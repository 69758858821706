<template>
  <div @click="dialogVisible=true">
    <slot></slot>
    <el-dialog custom-class="custom" append-to-body title="选择代理区域" :show-close="false" :visible.sync="dialogVisible"
               width="50rem">
      <y_choose_area_pro @change="change" :mode="this.mode"></y_choose_area_pro>
      <span slot="footer" class="dialog-footer">
         <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import y_choose_area_pro from "@/components/y_choose_area_pro/y_choose_area_pro";

export default {
  components: {y_choose_area_pro},
  name: "set-area",
  props: {
    uid: {
      type: Number,
      default: 0
    },
    mode: {
      type: Number,
      default: 3
    }
  },
  data() {
    return {
      dialogVisible: false,
      value:[],
    }
  },
  mounted() {

  },
  methods: {
    change(e){
      this.value = e;
    },
    submit(){
      if(this.value.length === 0){
        return this.$message.error("请选择要指定的区域")
      }
      let form = {
        uid:this.uid,
        province:{},
        city:{},
        district:{},
        street:{},
      };
      switch(parseInt(this.mode)){
        case 4:
          form.street = this.value[3];
          form.district = this.value[2];
          form.city = this.value[1];
          form.province = this.value[0];
          break;
        case 3:
          form.district = this.value[2];
          form.city = this.value[1];
          form.province = this.value[0];
          break;
        case 2:
          form.city = this.value[1];
          form.province = this.value[0];
          break;
        case 1:
          form.province = this.value[0];
          break;
      }
      this.$api.addons.areaAgent.setArea(form).then(()=>{
        this.dialogVisible = false;
        this.$message.success("操作成功")
        this.$emit("reload")
      })
    },
  }
}
</script>

<style scoped>

</style>